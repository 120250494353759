import React from 'react'
import { Router, Redirect } from '@reach/router'

const Index = () => {
  return (
    <>
      <React.Fragment>
        <Router>
          <Redirect noThrow from="/" to="/homepage/" />
        </Router>
      </React.Fragment>
    </>
  )
}

export default Index
